* {
  box-sizing: border-box;
  margin: 0;
}

.wrapper {
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

html,
#root,
body {
  height: 100%;
}

body {
  background-color: rgb(249, 250, 251);
  @media (prefers-color-scheme: dark) {
    background-color: rgb(29, 37, 45);
  }
}

.canvas {
  width: 100%;
  height: 100%;
  position: absolute;
}

.playButton {
  position: absolute;
  top: calc(50vh - 4rem);
  align-self: center;
  width: 8rem;
  height: 8rem;
  color: rgb(29, 37, 45);
  @media (prefers-color-scheme: dark) {
    color: rgb(249, 250, 251);
  }
}

.menu {
  width: 100%;
  color: #fff;
  align-self: end;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.menuWrapper {
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.menuWrapper span {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2rem;
  width: 2rem;
  border-radius: 10000px;
  border-width: 2px;
  border-style: solid;
  border-color: rgb(29, 37, 45);
  color: rgb(29, 37, 45);
  @media (prefers-color-scheme: dark) {
    border-color: rgb(249, 250, 251);
    color: rgb(249, 250, 251);
  }
  font-size: 1rem;
  margin-bottom: 0.4rem;
}

.music {
}

#input {
}

input[type="file"] {
  display: none;
}

.custom-file-upload {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;

  @media (prefers-color-scheme: light) {
    background-color: rgb(29, 37, 45);
  }
}

#root-loading {
  width: 100%;
  height: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.root-spinner {
  font-size: 0;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  padding: 0;
  animation-name: loading;
  animation-duration: 0.7s;
  animation-iteration-count: infinite;

  border-width: 3px;
  border-style: solid;
  border-color: rgb(29, 37, 45);
  border-bottom-color: rgba(29, 37, 45, 0);
  border-left-color: rgba(29, 37, 45, 0);

  @media (prefers-color-scheme: dark) {
    border-color: rgb(249, 250, 251);
    border-bottom-color: rgba(249, 250, 251, 0);
    border-left-color: rgba(249, 250, 251, 0);
  }
}

@keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
